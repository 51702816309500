import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/snippet-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p className="t-lead">LearnUXD brings you this course on basic User Experience (UX) and Interface Design. Learn from some of the leading experts in the field of User Experience about usability, user research, and the user-centered approach to designing digital interfaces and products.</p>
    <p>{`By the end of this course students will be able to:`}</p>
    <ul>
      <li parentName="ul">{`Explain concepts of effective user interaction design and apply them to plan and critique interactive interfaces for a variety of media.`}</li>
      <li parentName="ul">{`Develop their abilities with rapid sketching, particularly for the benefit of developing and testing interface concepts.`}</li>
      <li parentName="ul">{`Create prototype versions of interactive interfaces using concept sketches, wireframes, and static technologies that can be used for user testing and for demonstrations to clients and developers.`}</li>
      <li parentName="ul">{`Demonstrate an increased value for human-centered design and stewardship, especially from a Biblical perspective.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      